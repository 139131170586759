import { getRequest, postRequest, getImageBase64 } from "../lib/request";

export const labellingApi = {
    async home() {
        return await getRequest('/');
    },

    async getPage(params) {
        return await getRequest('/animal/queryList', params);
    },

    async getImageNum(params) {
        return await getRequest('/animal/queryList/count', params);
    },

    async save(params) {
        return await postRequest('/animal/mark', params);
    },

    async getAllLabelledImagesNum(params) {
        return await getRequest('/animal/queryLabelledImagesByProject', params);
    },

    async getAllImagesNum(params) {
        return await getRequest('/animal/queryAllImagesByProject', params);
    },

    async getAllValidatedImagesNum(params) {
        return await getRequest('/animal/queryImagesNumByProjectAndStatus', params);
    },

    // async getAllUnValidatedImagesNum(params) {
    //     return await getRequest('/animal/queryAllUnValidatedImagesByProject', params);
    // },


    async getAllLabelledImagesNumByUser(params) {
        return await getRequest('/animal/queryHistoryCountByUser', params);
    },

    async getLabelledImagesListByUser(params) {
        return await getRequest('/animal/queryHistoryCountListByUser', params);
    },

    async getAllUsersLabelCount(params) {
        return await getRequest('/animal/queryAllUserLabelledNum', params);
    },
};